import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
    return (
        <Typewriter
            options={{
                strings: ["Unleashing Success through Proven Link Building", "Crafting success with time-proven link-building expertise.", "Beyond links: a personalized strategy for your digital ascent.", "Your triumph, our commitment – let's redefine digital success.", "Precision link-building, tailored strategies – your triumph is our mission."],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
            }}
        />
    );
}

export default Type;
