import preet from "../../Assets/preet.jpeg";
import amit from "../../Assets/amit.jpg";
import madhuri from "../../Assets/mdk.jpeg";
import SUBHADEEP from "../../Assets/SUBHADEEP.jpg";
import Samapti from "../../Assets/Samapti.jpg";
import barsha from "../../Assets/barsha.jpg";
// import defaultImage from "../../Assets/default-avatar.png";
import Sourav from "../../Assets/Sourav.jpg";
import somnath from "../../Assets/somnath.jpg";
const teamMembers = [
    { name: "Preetiranjan Kundu", link: "Preetiranjan", role: "Founder & CEO", img: preet },
    { name: "Amit Kundu", link: "Amit", role: "SEO Executive", img: amit },
    { name: "Madhuri Kundu", link: "Madhuri", role: "SEO Executive", img: madhuri },
    { name: "Subhadeep Mandal", link: "Subhadeep", role: "SEO Executive", img: SUBHADEEP },
    { name: "Samapti Kundu", link: "Samapti", role: "SEO Executive", img: Samapti },
    { name: "Sourav Dey", link: "Sourav", role: "SEO Executive", img: Sourav },
    { name: "Barsha Mandal", link: "Barsha", role: "SEO Executive", img: barsha },
    { name: "Somnath Rakshit", link: "Somnath", role: "SEO Executive", img: somnath },
];

export default teamMembers;
