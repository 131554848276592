import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { AiOutlineUsergroupAdd, AiOutlineHome, AiOutlineQuestionCircle, AiFillCustomerService, AiFillContacts } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faUser, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <Container fluid className="footer">
            <Row>
                <Col md={3} xs={6} sm={6} lg={3} className="home-about-description">
                    <h1>WEB CONSULTENCY SERVICE</h1>
                    <p>We have years of experience in digital marketing and content creation, ensuring only the best for our clients.</p>
                </Col>
                <Col md={3} xs={6} sm={6} lg={3} className="home-about-description">
                    <h1 style={{ color: "white" }}>Links</h1>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/" style={{ color: "white" }}>
                            <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/our-team" style={{ color: "white" }}>
                            <AiOutlineUsergroupAdd style={{ marginBottom: "2px" }} /> Our Team
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/our-service" style={{ color: "white" }}>
                            <AiFillCustomerService style={{ marginBottom: "2px" }} /> Our Services
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/contact" style={{ color: "white" }}>
                            <AiFillContacts style={{ marginBottom: "2px" }} /> Contact
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/requirement-form" style={{ color: "white" }}>
                            <AiOutlineQuestionCircle style={{ marginBottom: "2px" }} /> Requirement Form
                        </Nav.Link>
                    </Nav.Item>
                </Col>

                <Col md={3} xs={6} sm={6} lg={3} className="home-about-description">
                    <h1 style={{ color: "white" }}>Services</h1>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/read-more/seo" style={{ color: "white" }}>
                            <p style={{ marginBottom: "2px" }}>SEO</p>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/read-more/link" style={{ color: "white" }}>
                            <p style={{ marginBottom: "2px" }}>Link Building & Backlinks</p>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/read-more/blog" style={{ color: "white" }}>
                            <p style={{ marginBottom: "2px" }}>Blogger Outreach</p>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link as={Link} to="/read-more/content" style={{ color: "white" }}>
                            <p style={{ marginBottom: "2px" }}>Content Marketing</p>
                        </Nav.Link>
                    </Nav.Item>
                </Col>

                <Col md={3} xs={6} sm={6} lg={3} className="home-about-description">
                    <h1>Office</h1>
                    <p>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> India
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faUser} /> CEO: Preetiranjan Kundu
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> Bankura, West Bengal, 722101
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faEnvelope} /> E-mail: preetiranjankundu.seo@gmail.com
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faPhone} /> Phone no.: +91 9332686302
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="footer-copywright">
                    <h3>Copyright © {year} Web Consultency Service</h3>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
