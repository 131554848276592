import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import TruncateDescription from "./TruncateDescription";

function ProjectCards(props) {
    return (
        <Card className="project-card-view">
            <Card.Img
                variant="top"
                src={props.imgPath}
                alt="card-img"
                // style={props?.page ? { width: 'auto', height: 'auto' } : { }}
            />
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <TruncateDescription description={props.description} maxLength={25} />
                {"\n"}
                {"\n"}

                {props.isBlog && (
                    <Nav.Item>
                        <Nav.Link as={Link} to={props.demoLink}>
                            <Button variant="primary" style={{ marginLeft: "10px" }}>
                                <CgWebsite /> &nbsp;
                                {"Member Details"}
                            </Button>
                        </Nav.Link>
                    </Nav.Item>
                )}

                {!props.isBlog && props.demoLink && (
                    <Nav.Item>
                        <Nav.Link as={Link} to={props.demoLink}>
                            <Button variant="primary" style={{ marginLeft: "10px" }}>
                                <CgWebsite /> &nbsp;
                                {"Read more"}
                            </Button>
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Card.Body>
        </Card>
    );
}
export default ProjectCards;
