import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { faMapMarkerAlt, faUser, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import React, { useRef } from "react";
import { MdAttachEmail } from "react-icons/md";
import axios from "axios";
const ContactForm = () => {
    const [formData, setFormData] = useState({
        user_name: "",
        user_email: "",
        contact: "",
        message: "",
    });

    const [errors, setErrors] = useState({});
    const form = useRef();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validation
        const newErrors = {};
        if (!formData.user_name.trim()) newErrors.user_name = "Name is required.";
        if (!formData.user_email.trim()) newErrors.user_email = "Email is required.";
        else if (!/^\S+@\S+\.\S+$/.test(formData.user_email)) newErrors.user_email = "Invalid email format.";
        if (!formData.message.trim()) newErrors.message = "Message is required.";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            const headers = {
                authority: "webconsultencyservice.com",
                accept: "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/x-www-form-urlencoded",
                cookie: "__gsas=ID=a61901dd8b1f6088:T=1707633989:RT=1707633989:S=ALNI_Ma5LSOiq43y12qCKbYm59HbQ9mW0w; pvisitor=34563796-9fe9-401f-a2a6-5f8f38921e9a; _policy=%7B%22restricted_market%22:true,%22tracking_market%22:%22explicit%22%7D; visitor=vid=7f51e255-a7e8-4e6a-bbe7-76298c356a52; PHPSESSID=3260f501a3ff8255337b091a8e5f483b; pathway=1b99cac6-98f3-432f-b154-a8cc77c9dbc6; fb_sessiontraffic=C_TOUCH=2024-02-25T09:06:03.110Z&pathway=1b99cac6-98f3-432f-b154-a8cc77c9dbc6&V_DATE=2024-02-25T09:06:03.105Z&pc=1; OPTOUTMULTI=0:0%7Cc2:1%7Cc9:1%7Cc11:1; pwinteraction=Sun%2C%2025%20Feb%202024%2009%3A06%3A15%20GMT",
                origin: "https://webconsultencyservice.com",
                referer: "https://webconsultencyservice.com/tatt.php",
                "sec-ch-ua": '"Chromium";v="122", "Not(A:Brand";v="24", "Microsoft Edge";v="122"',
                "sec-ch-ua-mobile": "?0",
                "sec-ch-ua-platform": '"Windows"',
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin",
                "user-agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36 Edg/122.0.0.0",
            };
            const messageContent = `
      User Name: ${formData.user_name}
      User Email: ${formData.user_email}
      Contact: ${formData.contact}
      Message: ${formData.message}
      `;

            const data = new URLSearchParams({
                sendemail: 1,
                toemail: "preetiranjankundu@gmail.com",
                from: "preetiranjankundu@webconsultencyservice.com",
                subject: "Contact form submission",
                sendmethod: "smtp",
                message: messageContent,
            });

            try {
                await axios.post("https://webconsultencyservice.com/tatt.php", data, { headers });
                setFormData({
                    user_name: "",
                    user_email: "",
                    contact: "",
                    message: "",
                });
                // // Reset errors
                setErrors({});
                alert("Email sent sucessfully!");
            } catch (error) {
                console.error("Error sending email:", error);
            }
        }
    };
    return (
        <Container fluid className="project-section">
            <Container>
                <Row className="justify-content-center">
                    <Col md={4} className="text-right">
                        <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light" style={{ height: "auto", width: "auto", marginBottom: "300px" }}>
                            <div className="card-header bg-transparent border-0 text-center text-uppercase">
                                <h3 style={{ margin: "auto", color: "black" }}>Contact Us</h3>
                            </div>
                            <div className="card-body">
                                <form ref={form} onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off">
                                    <div className="form-group">
                                        <label>
                                            Your name<span className="text-danger">*</span>
                                        </label>
                                        <input name="user_name" type="text" className="form-control" placeholder="Name" value={formData.user_name} onChange={handleChange} />
                                        {errors.user_name && <div className="alert alert-danger mt-2">{errors.user_name}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Your email<span className="text-danger">*</span>
                                        </label>
                                        <input name="user_email" type="email" className="form-control" placeholder="Email" value={formData.user_email} onChange={handleChange} />
                                        {errors.user_email && <div className="alert alert-danger mt-2">{errors.user_email}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>Your contact number (Optional)</label>
                                        <input name="contact" type="text" className="form-control" placeholder="Contact" value={formData.contact} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Message<span className="text-danger">*</span>
                                        </label>
                                        <textarea name="message" type="text" className="form-control" placeholder="Message" value={formData.message} onChange={handleChange} />
                                        {errors.message && <div className="alert alert-danger mt-2">{errors.message}</div>}
                                    </div>
                                    <br></br>
                                    <p className="text-center mb-0">
                                        <input type="submit" className="btn btn-primary btn-lg w-100 text-uppercase" value="Submit Now" />
                                    </p>
                                </form>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="justify-content-center">
                        <div className="card-body" style={{ textAlign: "center", color: "white", marginTop: "129px" }}>
                            <p>
                                <FontAwesomeIcon icon={faMapMarkerAlt} /> India
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faUser} /> CEO: Preetiranjan Kundu
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faMapMarkerAlt} /> Bankura, West Bengal, 722101
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faEnvelope} /> Email: preetiranjankundu.seo@gmail.com
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faPhone} /> Phone no.: +91 9332686302
                            </p>
                            <Button variant="primary" href="mailto:preetiranjankundu@gmail.com">
                                <MdAttachEmail /> &nbsp;
                                {"Connect via Email"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ContactForm;
