import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import React, { useRef } from "react";
import axios from "axios";
const RequirementForm = () => {
    const [formData, setFormData] = useState({
        user_name: "",
        user_email: "",
        company_name: "",
        domain_authority: "",
        domain_rating: "",
        organic_traffic: "",
        need_content: "",
        hunting_for: "",
        traffice_source: "",
    });

    const [errors, setErrors] = useState({});
    const form = useRef();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validation
        const newErrors = {};
        if (!formData.user_name || !formData.user_name.trim()) newErrors.user_name = "Name is required.";
        if (!formData.user_email || !formData.user_email.trim()) newErrors.user_email = "Email is required.";
        else if (!/^\S+@\S+\.\S+$/.test(formData.user_email)) newErrors.user_email = "Invalid email format.";
        if (!formData.message || !formData.message.trim()) newErrors.message = "Message is required.";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            console.log("newErrors", newErrors);
        } else {
            const headers = {
                authority: "webconsultencyservice.com",
                accept: "*/*",
                "accept-language": "en-US,en;q=0.9",
                "content-type": "application/x-www-form-urlencoded",
                cookie: "__gsas=ID=a61901dd8b1f6088:T=1707633989:RT=1707633989:S=ALNI_Ma5LSOiq43y12qCKbYm59HbQ9mW0w; pvisitor=34563796-9fe9-401f-a2a6-5f8f38921e9a; _policy=%7B%22restricted_market%22:true,%22tracking_market%22:%22explicit%22%7D; visitor=vid=7f51e255-a7e8-4e6a-bbe7-76298c356a52; PHPSESSID=3260f501a3ff8255337b091a8e5f483b; pathway=1b99cac6-98f3-432f-b154-a8cc77c9dbc6; fb_sessiontraffic=C_TOUCH=2024-02-25T09:06:03.110Z&pathway=1b99cac6-98f3-432f-b154-a8cc77c9dbc6&V_DATE=2024-02-25T09:06:03.105Z&pc=1; OPTOUTMULTI=0:0%7Cc2:1%7Cc9:1%7Cc11:1; pwinteraction=Sun%2C%2025%20Feb%202024%2009%3A06%3A15%20GMT",
                origin: "https://webconsultencyservice.com",
                referer: "https://webconsultencyservice.com/tatt.php",
                "sec-ch-ua": '"Chromium";v="122", "Not(A:Brand";v="24", "Microsoft Edge";v="122"',
                "sec-ch-ua-mobile": "?0",
                "sec-ch-ua-platform": '"Windows"',
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-origin",
                "user-agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36 Edg/122.0.0.0",
            };
            const messageContent = `
                User Name: ${formData.user_name}
                User Email: ${formData.user_email}
                My company Name: ${formData.company_name}
                Domain Authority: ${formData.domain_authority}
                Domain Rating: ${formData.domain_rating}
                Organic Traffic: ${formData.organic_traffic}
                Need Content: ${formData.need_content}
                Hunting For: ${formData.hunting_for}
                Traffice Source: ${formData.traffice_source}
                Message: ${formData.message}
                `;

            const data = new URLSearchParams({
                sendemail: 1,
                toemail: "preetiranjankundu@gmail.com",
                from: "preetiranjankundu@webconsultencyservice.com",
                subject: "Contact form Requirement Form",
                sendmethod: "smtp",
                message: messageContent,
            });

            try {
                await axios.post("https://webconsultencyservice.com/tatt.php", data, { headers });
                setFormData({
                    user_name: "",
                    user_email: "",
                    company_name: "",
                    domain_authority: "",
                    domain_rating: "",
                    organic_traffic: "",
                    need_content: "",
                    hunting_for: "",
                    traffice_source: "",
                });
                // // Reset errors
                setErrors({});
                alert("Requirement Form Submit sucessfully!");
            } catch (error) {
                console.error("Error sending email:", error);
            }
        }
    };
    return (
        <Container fluid className="project-section">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="text-right">
                        <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light" style={{ height: "auto", width: "auto", marginBottom: "300px" }}>
                            <div className="card-header bg-transparent border-0 text-center text-uppercase">
                                <h3 style={{ margin: "auto", color: "black" }}>Requirement Form</h3>
                            </div>
                            <div className="card-body">
                                <form ref={form} onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off">
                                    <div className="form-group">
                                        <label>
                                            Your name<span className="text-danger">*</span>
                                        </label>
                                        <input name="user_name" type="text" className="form-control" placeholder="Please Enter Your Full Name Here" value={formData.user_name} onChange={handleChange} />
                                        {errors.user_name && <div className="alert alert-danger mt-2">{errors.user_name}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Your email<span className="text-danger">*</span>
                                        </label>
                                        <input name="user_email" type="email" className="form-control" placeholder="Please Enter Your Valid Email Address" value={formData.user_email} onChange={handleChange} />
                                        {errors.user_email && <div className="alert alert-danger mt-2">{errors.user_email}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>Company Name </label>
                                        <input name="company_name" type="text" className="form-control" placeholder="Enter Your Company Name" value={formData.company_name} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label class="exampleInputEmail1">Domain Authority (DA)</label>
                                        <select class="form-control form-control-lg" name="domain_authority" value={formData.domain_authority} onChange={handleChange}>
                                            <option value="">Select Domain Authority</option>
                                            <option value="DA 30+">DA 30+</option>
                                            <option value="DA 40+">DA 40+</option>
                                            <option value="DA 50 to DA79">DA 50 to DA79</option>
                                            <option value="DA 80+">DA 80+</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div class="form-group">
                                        <label class="exampleInputEmail1">Domain Rating (DR)</label>
                                        <select class="form-control form-control-lg" name="domain_rating" value={formData.domain_rating} onChange={handleChange}>
                                            <option value="">Select Domain Rating</option>
                                            <option value="DR 30+">DR 30+</option>
                                            <option value="DR 40+">DR 40+</option>
                                            <option value="DR 50 to DR 79">DR 50 to DR 79 </option>
                                            <option value="DR 80+">DR 80+</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div class="form-group">
                                        <label class="exampleInputEmail1">Organic Traffic</label>
                                        <select class="form-control form-control-lg" name="organic_traffic" value={formData.organic_traffic} onChange={handleChange}>
                                            <option value="">Select Organic Traffic</option>
                                            <option value="1000+">1000+</option>
                                            <option value="10000+">10000+</option>
                                            <option value="500+">500+</option>
                                            <option value="5000+">5000+</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div class="form-group">
                                        <label class="exampleInputEmail1">Need Content</label>
                                        <select class="form-control form-control-lg" name="need_content" value={formData.need_content} onChange={handleChange}>
                                            <option value="">Select Need Content</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div class="form-group">
                                        <label class="exampleInputEmail1">Hunting For</label>
                                        <select class="form-control form-control-lg" name="hunting_for" value={formData.hunting_for} onChange={handleChange}>
                                            <option value="">Select Hunting For</option>
                                            <option value="Guest Post">Guest Post</option>
                                            <option value="Link Placement Only">Link Placement Only</option>
                                            <option value="Niche Edit">Niche Edit</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div class="form-group">
                                        <label class="exampleInputEmail1">Traffice Source</label>
                                        <select class="form-control form-control-lg" name="traffice_source" value={formData.traffice_source} onChange={handleChange}>
                                            <option value="">Select Traffice Source</option>
                                            <option value="USA">USA</option>
                                            <option value="UK">UK</option>
                                            <option value="India">India</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <small id="emailHelp" class="form-text text-danger"></small>
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Let us know what's your Requirement for Now<span className="text-danger">*</span>
                                        </label>
                                        <textarea name="message" type="text" className="form-control" placeholder="Message" value={formData.message} onChange={handleChange} />
                                        {errors.message && <div className="alert alert-danger mt-2">{errors.message}</div>}
                                    </div>
                                    <br></br>
                                    <p className="text-center mb-0">
                                        <input type="submit" className="btn btn-primary btn-lg w-100 text-uppercase" value="Submit Now" />
                                    </p>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default RequirementForm;
