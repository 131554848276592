import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import seo from "../../Assets/seo.png";
import content from "../../Assets/content.png";
import bloger from "../../Assets/bloger.png";
import linkbuilding from "../../Assets/linkbuilding.png";
import teamWork from "../../Assets/teamWork.jpg";
import Tilt from "react-parallax-tilt";
// import Button from "react-bootstrap/Button";
// import { Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";
import ContactForm from "../Contact/contact";
import Member from "../OurTeam/Member";
// import {
//   AiOutlineTwitter,
//   AiFillInstagram,
// } from "react-icons/ai";
//import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>Your Preferences, Our Mission Customized Service.</h1>
                        <p className="home-about-body">
                            At Web Consultency Service, we prioritize your success through a comprehensive evaluation of your site and market dynamics. No two businesses are alike, and neither should their online strategies be. We firmly believe in the absence of a 'one size fits all' solution in online marketing. That's why we dedicate ourselves to crafting unique strategies tailored specifically to your business needs. Our commitment extends to accepting custom orders, ensuring your digital success is as unique as your brand. Partner with us for personalized excellence in online marketing.
                            <br />
                            {/* <br />I am fluent in classics like
              <i>
                <b className="purple"> Php, Laravel and Codeigniter. </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  Deep Learning and Natural Launguage Processing.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js etc.</b>
              </i>*/}
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="avatar" />
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>Our Services</h1>
                        <Row>
                            <Col md={3} className="myAvtar">
                                <Tilt>
                                    <img src={seo} className="img-fluid" alt="avatar" />
                                    <p style={{ fontSize: "40px" }}>SEO</p>
                                </Tilt>
                            </Col>
                            <Col md={3} className="myAvtar">
                                <Tilt>
                                    <img src={linkbuilding} className="img-fluid" alt="avatar" />
                                    <p style={{ fontSize: "40px" }}>Link Building & Backlinks</p>
                                </Tilt>
                            </Col>
                            <Col md={3} className="myAvtar">
                                <Tilt>
                                    <img src={content} className="img-fluid" alt="avatar" />
                                    <p style={{ fontSize: "40px" }}>Content Marketing</p>
                                </Tilt>
                            </Col>
                            <Col md={3} className="myAvtar">
                                <Tilt>
                                    <img src={bloger} className="img-fluid" alt="avatar" />
                                    <p style={{ fontSize: "40px" }}>Bloger Outreach</p>
                                </Tilt>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>Our team of experts is here to help</h1>
                        <p className="home-about-body">
                            We are a team of experts having practical knowledge and years of experience to optimize your website. We provide Natural, relevant, in-content links, from 100% real bloggers.
                            <br />
                            <br />
                            <strong className="purple">Experience: </strong> &nbsp;&nbsp; 12+
                            <br />
                            <strong className="purple">People: </strong>&nbsp;&nbsp; 100+
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={teamWork} className="img-fluid" alt="avatar" />
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>CONNECT WITH US</h1>
                        <p>
                            Feel free to connect with us
                            <br />
                            Let's build something together!
                        </p>
                        {/* <ul className="home-about-social-links">
            </ul> */}
                    </Col>
                    <ContactForm />
                    <Member />
                </Row>
            </Container>
        </Container>
    );
}
export default Home2;
