import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Service from "./components/Services/services";
import Contact from "./components/Contact/contact";
import Footer from "./components/Footer";
import ReadMore from "./components/Services/readMore";
import RequirementForm from "./components/Requirement/RequirementForm";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Member from "./components/OurTeam/Member";

function App() {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Router>
            <Preloader load={load} />
            <div className="App" id={load ? "no-scroll" : "scroll"}>
                <Navbar />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/our-team" element={<Member />} />
                    <Route path="/our-service" element={<Service />} />
                    <Route path="/read-more/:name" element={<ReadMore />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/requirement-form" element={<RequirementForm />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
