import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/weblogo.jpg";
import { Link } from "react-router-dom";

import { AiOutlineHome, AiOutlineUsergroupAdd, AiFillCustomerService, AiFillContacts, AiOutlineQuestionCircle } from "react-icons/ai";

function NavBar() {
    const [expand, updateExpanded] = useState(false);
    const [navColour, updateNavbar] = useState(false);

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true);
        } else {
            updateNavbar(false);
        }
    }

    window.addEventListener("scroll", scrollHandler);

    return (
        <Navbar expanded={expand} fixed="top" expand="md" className={navColour ? "sticky" : "navbar"}>
            <Container fluid>
                <Nav.Item className="d-flex">
                    <Nav.Link as={Link} to="/">
                        <img
                            src={logo}
                            className="img-fluid logo"
                            alt="brand"
                            // style={{ width: '1000px', height: '800px' }}
                        />
                    </Nav.Link>
                </Nav.Item>
                <span style={{ color: "white", fontSize: '1.6rem' }}>
                    Web Consultancy Service
                </span>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : "expanded");
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/our-team" onClick={() => updateExpanded(false)}>
                                <AiOutlineUsergroupAdd style={{ marginBottom: "2px" }} /> Our Team
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/our-service" onClick={() => updateExpanded(false)}>
                                <AiFillCustomerService style={{ marginBottom: "2px" }} /> Our Services
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link as={Link} to="/contact" onClick={() => updateExpanded(false)}>
                                <AiFillContacts style={{ marginBottom: "2px" }} /> Contact
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link as={Link} to="/requirement-form" onClick={() => updateExpanded(false)}>
                                <AiOutlineQuestionCircle style={{ marginBottom: "2px" }} /> Requirement Form
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
