import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import seoreadmore from "../../Assets/seoreadmore.jpg";
import linkreadmore from "../../Assets/linkreadmore.png";
import bloreadmore from "../../Assets/bloreadmore.png";
import contentreadmore from "../../Assets/contentreadmore.jpeg";
import Tilt from "react-parallax-tilt";
// Assume readMore is a functional component
function ReadMore() {
    let { name } = useParams();
    let title, body, imgSrc;
    if (name === "seo") {
        title = "Search Engine Optimization";
        body = "The importance of search engine optimization (SEO) cannot be ignored today. Most people will not go past the first page when they are searching on Google, so it is important that your business features near the top. This is not something that can happen overnight. It takes natural, white hat strategies, which will organically move your business up the result pages without penalties. This is exactly what you will receive when you work with us. We have helped many clients, from all over the world, to enhance their online search engine presence. We will develop a strategy that is unique to you and your business, incorporating everything from keyword research to content development and link building.";
        imgSrc = seoreadmore;
    } else if (name === "content") {
        title = "Content Marketing";
        body = "We have a team of experienced, seasoned native writers who can produce content for your project. We are capable of delivering content for any brief or requirement, whether it is a PR piece, newsletter, website content, blog, news story, or even content for your offline marketing efforts. Our writers pride themselves on producing content that is original, exciting, and engaging. We know what it takes to deliver content that delivers results. We will ensure that the piece not only reads beautifully but that it has been optimized as well. We run all content through Copyscape and Grammarly. Plus, our writers spend hours researching topics and getting familiar with your business so that they can produce content you are happy with.";
        imgSrc = contentreadmore;
    } else if (name === "link") {
        title = "Link Building & Backlinks";
        body = "Link building is essentially about creating as many “thumbs ups” for your website as possible. When another website links to your website, it is essentially telling Google that your site is legit and that they rate you. However, where a lot of people go wrong is that they try to build as many links as possible with little thought for the quality of those links. If you run a beauty parlor in New York, and there is a mechanic in Hong Kong that is linking to your beauty website, it doesn’t really make sense, does it? This is the sort of thing that Google will penalize. This is why it is imperative to make sure all of the links come from websites that have high domain authority and are relevant to your business. With our experience and contacts in the industry, we can make sure that this is the case.";
        imgSrc = linkreadmore;
    } else if (name === "blog") {
        title = "Blogger Outreach";
        body = "You know what they say; content is king! This is definitely the case, and it is important to build your authority online with the aid of exciting, interesting, and engaging content. However, not only should you be posting content on your own blog but you need to reach out to other blogs as well. Posting content on other blogs will enable you to tap into their readymade audience while also benefitting from backlinks back to your own website. This is a great way of reaching more traffic and also increasing your search engine ranking in the process. Yet, this will only be achieved if you team up with leading bloggers who have a great presence in your industry and a high domain rating. We have an extensive database of blogs and we have excellent relationships with bloggers around the world, so we can make sure that your blog posts are posted on websites that will really make a big difference and drive results for your business.";
        imgSrc = bloreadmore;
    } else {
        title = "";
        body = "Oops! The requested service was not found.";
        imgSrc = "";
    }

    if (!title && !imgSrc) {
        return (
            <Container fluid className="project-section">
                <Row>
                    <Col md={12} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>{body}</h1>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container fluid className="project-section">
            <Row>
                <Col md={12} className="home-about-social">
                    <h1 style={{ textAlign: "center" }}>{title}</h1>
                </Col>
            </Row>
            {name && (
                <Row>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={imgSrc} className="img-fluid" alt="avatar" style={{ width: "400px", height: "350px" }} />
                        </Tilt>
                    </Col>
                    <Col md={8} className="home-about-description">
                        {/* <h1 style={{ fontSize: "2.6em" }}>{title}</h1> */}
                        <p className="home-about-body" style={{ textAlign: "center" }}>
                            {body}
                        </p>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ReadMore;
