import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCards from "../Projects/ProjectCards";
import seo from "../../Assets/seo.png";
import content from "../../Assets/content.png";
import bloger from "../../Assets/bloger.png";
import linkbuilding from "../../Assets/linkbuilding.png";

function services() {
    return (
        <Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">Our Services </strong>
                </h1>
                {/* <h3 className="project-heading">
        WHO WE ARE
      </h3>
      <p style={{ color: "white" }}>
        We are a team of experts having practical knowledge and years of experience to optimize your website.
        We provide Natural, relevant, in-content links, from 100% real bloggers.
      </p> */}
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    <Col md={3} className="project-card">
                        <ProjectCards imgPath={seo} isBlog={false} title="SEO" description="The importance of search engine optimization (SEO) cannot be ignored today" demoLink="/read-more/seo" page="service" />
                    </Col>
                    <Col md={3} className="project-card">
                        <ProjectCards imgPath={content} isBlog={false} title="Content Marketing" description="We have a team of experienced, seasoned native writers who can produce content for your project." demoLink="/read-more/content" page="service" />
                    </Col>
                    <Col md={3} className="project-card">
                        <ProjectCards imgPath={bloger} isBlog={false} title="Bloger Outreach" description="Blogger outreach is the process of strategically communicating and building relationships with industry influencers and publishers to achieve a specific goal," demoLink="/read-more/blog" page="service" />
                    </Col>
                    <Col md={3} className="project-card">
                        <ProjectCards imgPath={linkbuilding} isBlog={false} title="Link Building & Backlinks" description="Link building is essentially about creating as many “thumbs ups” for your website as possible." demoLink="/read-more/link" page="service" />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default services;
