import React from "react";
import { Card } from "react-bootstrap";

function TruncateDescription({ description, maxLength }) {
    if (description.length <= maxLength) {
        return <Card.Text style={{ textAlign: "center" }}>{description}</Card.Text>;
    }
    return <Card.Text style={{ textAlign: "center" }}>{description.substring(0, maxLength)}...</Card.Text>;
}

export default TruncateDescription;
