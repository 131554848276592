import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import teamMembers from "./teamMember";

function Member() {
    return (
        <Container fluid className="project-section">
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">Our Team </strong>
                </h1>
                <h3 className="project-heading">WHO WE ARE</h3>
                <p style={{ color: "white" }}>We are a team of experts having practical knowledge and years of experience to optimize your website. We provide Natural, relevant, in-content links, from 100% real bloggers.</p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    {teamMembers.map((member, index) => (
                        <Col key={index} md={4} sm={6} xs={12} lg={3} className="project-card">
                            <ProjectCard imgPath={member.img} isBlog={true} title={member.name} description={member.role} demoLink={`/member-details/${member.link}`} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default Member;
